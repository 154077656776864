import React from 'react'
import Layout from '../hoc/Layout/Layout'
import PrivacyPolicy from '../components/Pages/PrivacyPolicy/PrivacyPolicy'

export default () => {
  return (
    <Layout>
      <PrivacyPolicy />
    </Layout>
  )
}
