import React from 'react'
import { Link } from 'gatsby'
import SEO from '../../seo'
import classes from './PrivacyPolicy.module.css'

const privacyPolicy = () => {
  return (
    <div className={classes.PageContainer}>
      <SEO
        title="PMP Training | Privacy Policy | PMP Exam Simulator"
        keywords={[`PMP`, `PMP Exam`, `Project Management`]}
        description="Sample Questions to get PMP Certification. Privacy Policy for PMP Exam Simulator."
      ></SEO>
      <p className={classes.Title}>
        <strong>PRIVACY POLICY</strong>
      </p>
      <p className={classes.SubTitle}>
        <strong>Last updated November 29, 2019</strong>
      </p>
      <p>
        Thank you for choosing to be part of our community at ErudiCAT ("
        <strong>Company</strong>", "<strong>we</strong>", "<strong>us</strong>",
        or "<strong>our</strong>"). We are committed to protecting your personal
        information and your right to privacy. If you have any questions or
        concerns about our Site or our practices with regards to your personal
        information, please{' '}
        <Link to="/project-manager-contact">Contact Us</Link>.
      </p>
      <p>
        When you visit our website <Link to="/">https://www.erudicat.com</Link>,
        and use our services, you trust us with your personal information. We
        take your privacy very seriously. In this privacy policy, we seek to
        explain to you in the clearest way possible what information we collect,
        how we use it and what rights you have in relation to it. We hope you
        take some time to read through it carefully, as it is important. If
        there are any terms in this that you do not agree with, please
        discontinue use of our Sites and our services.
      </p>
      <p>
        This applies to all information collected through our website (such as{' '}
        <Link to="/">https://www.erudicat.com</Link>), and/or any related
        services, sales, marketing or events (we refer to them collectively in
        this privacy policy as the "<strong>Services</strong>").
      </p>
      <p className={classes.SubTitle}>
        <strong>
          Please read this carefully as it will help you make informed decisions
          about sharing your personal information with us.
        </strong>
      </p>
      <br />
      <p className={classes.Heading_1}>
        <strong>TABLE OF CONTENTS</strong>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#infocollect">1. WHAT INFORMATION DO WE COLLECT?</a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#infouse">2. HOW DO WE USE YOUR INFORMATION?</a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#inshare">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#whoshare">4. WHO WILL YOUR INFORMATION BE SHARED WITH?</a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#3pwebsites">5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#inforetain">6. HOW LONG DO WE KEEP YOUR INFORMATION?</a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#infosafe">7. HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#infominors">8. DO WE COLLECT INFORMATION FROM MINORS?</a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#privacyrights">9. WHAT ARE YOUR PRIVACY RIGHTS?</a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#databreach">10. DATA BREACH</a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#DNT">11. CONTROLS FOR DO-NOT-TRACK FEATURES</a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#caresidents">
          12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        </a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#policyupdates">13. DO WE MAKE UPDATES TO THIS POLICY?</a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#contact">14. HOW CAN YOU CONTACT US ABOUT THIS POLICY?</a>
      </p>
      <br />
      <p className={classes.Heading_1}>
        <span id="infocollect" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>1. WHAT INFORMATION DO WE COLLECT?</strong>
      </p>
      <p className={classes.Heading_2}>
        <strong>Personal information you disclose to us</strong>
      </p>
      <p>
        <strong>
          <em>In Short: </em>
        </strong>
        <em>
          We collect personal information that you provide to us such as name,
          address, contact information, passwords and security data, and payment
          information.
        </em>
      </p>
      <p>
        We collect personal information that you voluntarily provide to us when
        registering at the Services expressing an interest in obtaining
        information about us or our products and services, when participating in
        activities on the Services or otherwise contacting us.
      </p>
      <p>
        The personal information that we collect depends on the context of your
        interactions with us and the Services, the choices you make and the
        products and features you use. The personal information we collect can
        include the following:
      </p>
      <p>
        <strong>Publicly Available Personal Information.</strong>
        &nbsp;We collect first name, maiden name, last name, nickname, email
        addresses and other similar data.
      </p>
      <p>
        <strong>Personal Information Provided by You.</strong>
        &nbsp;We collect financial information (credit card number, purchase
        history, invoices), and other similar data.
      </p>
      <p>
        <strong>Credentials.</strong>
        &nbsp;We collect passwords, password hints, and similar security
        information used for authentication and account access.
      </p>
      <p>
        <strong>Payment Data.</strong>
        &nbsp;We collect data necessary to process your payment if you make
        purchases, such as your payment instrument number (such as a credit card
        number), and the security code associated with your payment instrument.
        All payment data is stored by Stripe. You may find their privacy policy
        link(s) here:{' '}
        <a href="https://stripe.com" target="_blank" rel="noopener noreferrer">
          https://stripe.com
        </a>
        .
      </p>
      <p>
        All personal information that you provide to us must be true, complete
        and accurate, and you must notify us of any changes to such personal
        information.
      </p>
      <p className={classes.Heading_1}>
        <span id="infouse" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>2. HOW DO WE USE YOUR INFORMATION?</strong>
      </p>
      <p>
        <strong>
          <em>In Short: </em>
        </strong>
        <em>
          We process your information for purposes based on legitimate business
          interests, the fulfillment of our contract with you, compliance with
          our legal obligations, and/or your consent.
        </em>
      </p>
      <p>
        We use personal information collected via our Services for a variety of
        business purposes described below. We process your personal information
        for these purposes in reliance on our legitimate business interests, in
        order to enter into or perform a contract with you, with your consent,
        and/or for compliance with our legal obligations. We indicate the
        specific processing grounds we rely on next to each purpose listed
        below.
      </p>
      <p>We use the information we collect or receive:</p>
      <ul>
        <li>
          <p>
            <span>
              <strong>
                To facilitate account creation and logon process.&nbsp;
              </strong>
              If you choose to link your account with us to a third party
              account (such as your Google or Facebook account), we use the
              information you allowed us to collect from those third parties to
              facilitate account creation and logon process for the performance
              of the contract.
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              <strong>
                To send you marketing and promotional communications.&nbsp;
              </strong>
              We and/or our third party marketing partners may use the personal
              information you send to us for our marketing purposes, if this is
              in accordance with your marketing preferences. You can opt-out of
              our marketing emails at any time (see the "
              <a href="#privacyrights">WHAT ARE YOUR PRIVACY RIGHTS</a>" below).
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              <strong>To send administrative information to you. &nbsp;</strong>
              We may use your personal information to send you product, service
              and new feature information and/or information about changes to
              our terms, conditions, and policies.
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              <strong>Fulfill and manage your orders.&nbsp;</strong>
              We may use your information to fulfill and manage your orders,
              payments, returns, and exchanges made through the Services.
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              <strong>Request Feedback.&nbsp;</strong>
              We may use your information to request feedback and to contact you
              about your use of our Services.
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              <strong>
                To respond to user inquiries/offer support to users.&nbsp;
              </strong>
              We may use your information to respond to your inquiries and solve
              any potential issues you might have with the use of our Services.
            </span>
          </p>
        </li>
      </ul>
      <p className={classes.Heading_1}>
        <span id="inshare" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</strong>
      </p>
      <p>
        <strong>
          <em>In Short: </em>
        </strong>
        <em>
          We only share information with your consent, to comply with laws, to
          provide you with services, to protect your rights, or to fulfill
          business obligations.{' '}
        </em>
      </p>
      <p>We may process or share data based on the following legal basis:</p>
      <ul>
        <li>
          <p>
            <span>
              <strong>Consent:&nbsp;</strong>
              We may process your data if you have given us specific consent to
              use your personal information in a specific purpose.
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              <strong>Legitimate Interests:&nbsp;</strong>
              We may process your data when it is reasonably necessary to
              achieve our legitimate business interests.
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              <strong>Performance of a Contract: &nbsp;</strong>
              Where we have entered into a contract with you, we may process
              your personal information to fulfill the terms of our contract.
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              <strong>Legal Obligations:&nbsp;</strong>
              We may disclose your information where we are legally required to
              do so in order to comply with applicable law, governmental
              requests, a judicial proceeding, court order, or legal process,
              such as in response to a court order or a subpoena (including in
              response to public authorities to meet national security or law
              enforcement requirements).
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              <strong>Vital Interests:&nbsp;</strong>
              We may disclose your information where we believe it is necessary
              to investigate, prevent, or take action regarding potential
              violations of our policies, suspected fraud, situations involving
              potential threats to the safety of any person and illegal
              activities, or as evidence in litigation in which we are involved.
            </span>
          </p>
        </li>
      </ul>
      <p>
        More specifically, we may need to process your data or share your
        personal information in the following situations:
      </p>
      <ul>
        <li>
          <p>
            <span>
              <strong>
                Vendors, Consultants and Other Third-Party Service
                Providers.&nbsp;
              </strong>
              We may share your data with third party vendors, service
              providers, contractors or agents who perform services for us or on
              our behalf and require access to such information to do that work.
              Examples include: payment processing, data analysis, email
              delivery, hosting services, customer service and marketing
              efforts. We may allow selected third parties to use tracking
              technology on the Services, which will enable them to collect data
              about how you interact with the Services over time. This
              information may be used to, among other things, analyze and track
              data, determine the popularity of certain content and better
              understand online activity. Unless described in this Policy, we do
              not share, sell, rent or trade any of your information with third
              parties for their promotional purposes. We have contracts in place
              with our data processors. This means that they cannot do anything
              with your personal information unless we have instructed them to
              do it. They will not share your personal information with any
              organization apart from us. They will hold it securely and retain
              it for the period we instruct.
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              <strong>Business Transfers.&nbsp;</strong>
              We may share or transfer your information in connection with, or
              during negotiations of, any merger, sale of company assets,
              financing, or acquisition of all or a portion of our business to
              another company.
            </span>
          </p>
        </li>
      </ul>
      <p className={classes.Heading_1}>
        <span id="whoshare" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>4. WHO WILL YOUR INFORMATION BE SHARED WITH?</strong>
      </p>
      <p>
        <strong>
          <em>In Short: </em>
        </strong>
        <em>We only share information with the following third parties.</em>
      </p>
      <p>
        We only share and disclose your information with the following third
        parties. We have categorized each party so that you may be easily
        understand the purpose of our data collection and processing practices.
        If we have processed your data based on your consent and you wish to
        revoke your consent, please{' '}
        <Link to="/project-manager-contact">Contact Us</Link>.
      </p>
      <ul>
        <li>
          <p>
            <span>
              <strong>
                Advertising, Direct Marketing, and Lead Generation&nbsp;
              </strong>
              Google AdSense
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              <strong>Content Optimization&nbsp;</strong>
              Google Site Search and Google Fonts
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              <strong>Invoice and Billing&nbsp;</strong>
              Stripe
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              <strong>Web and Mobile Analytics&nbsp;</strong>
              Google Analytics
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              <strong>Website Hosting&nbsp;</strong>
              Netlify
            </span>
          </p>
        </li>
      </ul>
      <p className={classes.Heading_1}>
        <span id="3pwebsites" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</strong>
      </p>
      <p>
        <strong>
          <em>In Short: </em>
        </strong>
        <em>
          We are not responsible for the safety of any information that you
          share with third-party providers who advertise, but are not affiliated
          with, our websites.
        </em>
      </p>
      <p>
        The Services may contain advertisements from third parties that are not
        affiliated with us and which may link to other websites, online services
        or mobile applications. We cannot guarantee the safety and privacy of
        data you provide to any third parties. Any data collected by third
        parties is not covered by this privacy policy. We are not responsible
        for the content or privacy and security practices and policies of any
        third parties, including other websites, services or applications that
        may be linked to or from the Services. You should review the policies of
        such third parties and contact them directly to respond to your
        questions.
      </p>
      <p className={classes.Heading_1}>
        <span id="inforetain" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>6. HOW LONG DO WE KEEP YOUR INFORMATION?</strong>
      </p>
      <p>
        <strong>
          <em>In Short: </em>
        </strong>
        <em>
          We keep your information for as long as necessary to fulfill the
          purposes outlined in this unless otherwise required by law.
        </em>
      </p>
      <p>
        We will only keep your personal information for as long as it is
        necessary for the purposes set out in this privacy policy, unless a
        longer retention period is required or permitted by law (such as tax,
        accounting or other legal requirements). No purpose in this policy will
        require us keeping your personal information for longer than the period
        of time in which users have an account with us.
      </p>
      <p>
        When we have no ongoing legitimate business need to process your
        personal information, we will either delete or anonymize it, or, if this
        is not possible (for example, because your personal information has been
        stored in backup archives), then we will securely store your personal
        information and isolate it from any further processing until deletion is
        possible.
      </p>
      <p className={classes.Heading_1}>
        <span id="infosafe" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>7. HOW DO WE KEEP YOUR INFORMATION SAFE? </strong>
      </p>
      <p>
        <strong>
          <em>In Short: </em>
        </strong>
        <em>
          We aim to protect your personal information through a system of
          organizational and technical security measures.
        </em>
      </p>
      <p>
        We have implemented appropriate technical and organizational security
        measures designed to protect the security of any personal information we
        process. However, please also remember that we cannot guarantee that the
        internet itself is 100% secure. Although we will do our best to protect
        your personal information, transmission of personal information to and
        from our Services is at your own risk. You should only access the
        services within a secure environment.
      </p>
      <p className={classes.Heading_1}>
        <span id="infominors" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>8. DO WE COLLECT INFORMATION FROM MINORS?</strong>
      </p>
      <p>
        <strong>
          <em>In Short: </em>
        </strong>
        <em>
          We do not knowingly collect data from or market to children under 18
          years of age.
        </em>
      </p>
      <p>
        We do not knowingly solicit data from or market to children under 18
        years of age. By using the Services, you represent that you are at least
        18 or that you are the parent or guardian of such a minor and consent to
        such minor dependent’s use of the Services. If we learn that personal
        information from users less than 18 years of age has been collected, we
        will deactivate the account and take reasonable measures to promptly
        delete such data from our records. If you become aware of any data we
        have collected from children under age 18, please{' '}
        <Link to="/project-manager-contact">Contact Us</Link>.
      </p>
      <p className={classes.Heading_1}>
        <span id="privacyrights" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>9. WHAT ARE YOUR PRIVACY RIGHTS?</strong>
      </p>
      <p>
        <strong>
          <em>In Short: </em>
        </strong>
        <em>
          In some regions, such as the European Economic Area, you have rights
          that allow you greater access to and control over your personal
          information. You may review, change, or terminate your account at any
          time.
        </em>
      </p>
      <p>
        In some regions (like the European Economic Area), you have certain
        rights under applicable data protection laws. These may include the
        right (i) to request access and obtain a copy of your personal
        information, (ii) to request rectification or erasure; (iii) to restrict
        the processing of your personal information; and (iv) if applicable, to
        data portability. In certain circumstances, you may also have the right
        to object to the processing of your personal information. To make such a
        request, please use the <a href="#contact">contact details</a>
        &nbsp;provided below. We will consider and act upon any request in
        accordance with applicable data protection laws.
      </p>
      <p>
        If we are relying on your consent to process your personal information,
        you have the right to withdraw your consent at any time. Please note
        however that this will not affect the lawfulness of the processing
        before its withdrawal.
      </p>
      <p>
        If you are resident in the European Economic Area and you believe we are
        unlawfully processing your personal information, you also have the right
        to complain to your local data protection supervisory authority. You can
        find their contact details here:&nbsp;
        <a
          href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
          target="_blank"
          rel="noopener noreferrer"
        >
          http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
        </a>
      </p>
      <p>
        If you have questions or comments about your privacy rights, you may{' '}
        <Link to="/project-manager-contact">Contact Us</Link>.
      </p>
      <p className={classes.Heading_2}>
        <strong>Account Information</strong>
      </p>
      <p>
        If you would at any time like to review or change the information in
        your account or terminate your account, you can:
      </p>
      <p className={classes.Bullet}>
        Log into your account settings and update your user account.
      </p>
      <p>
        Upon your request to terminate your account, we will deactivate or
        delete your account and information from our active databases. However,
        some information may be retained in our files to prevent fraud,
        troubleshoot problems, assist with any investigations, enforce our Terms
        of Use and/or comply with legal requirements.
      </p>
      <p>
        <strong>Opting out of email marketing:&nbsp;</strong>
        You can unsubscribe from our marketing email list at any time by
        clicking on the unsubscribe link in the emails that we send or by
        contacting us using the details provided below. You will then be removed
        from the marketing email list – however, we will still need to send you
        service-related emails that are necessary for the administration and use
        of your account. To otherwise opt-out, you may:
      </p>
      <p className={classes.Bullet}>
        Note your preferences when you register an account with the site.
      </p>
      <p className={classes.Heading_1}>
        <span id="databreach" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>10. DATA BREACH</strong>
      </p>
      <p>
        A privacy breach occurs when there is unauthorized access to or
        collection, use, disclosure or disposal of personal information. You
        will be notified about data breaches when ErudiCAT believes you are
        likely to be at risk or serious harm. For example, a data breach may be
        likely to result in serious financial harm or harm to your mental or
        physical well-being. In the event that ErudiCAT becomes aware of a
        security breach which has resulted or may result in unauthorized access,
        use or disclosure of personal information ErudiCAT will promptly
        investigate the matter and notify the applicable Supervisory Authority
        not later than 72 hours after having become aware of it, unless the
        personal data breach is unlikely to result in a risk to the rights and
        freedoms of natural persons.
      </p>
      <p className={classes.Heading_1}>
        <span id="DNT" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>11. CONTROLS FOR DO-NOT-TRACK FEATURES</strong>
      </p>
      <p>
        Most web browsers and some mobile operating systems and mobile
        applications include a Do-Not-Track ("DNT") feature or setting you can
        activate to signal your privacy preference not to have data about your
        online browsing activities monitored and collected. No uniform
        technology standard for recognizing and implementing DNT signals has
        been finalized. As such, we do not currently respond to DNT browser
        signals or any other mechanism that automatically communicates your
        choice not to be tracked online. If a standard for online tracking is
        adopted that we must follow in the future, we will inform you about that
        practice in a revised version of this privacy policy.
      </p>
      <p className={classes.Heading_1}>
        <span id="caresidents" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>
          12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        </strong>
      </p>
      <p>
        <strong>
          <em>In Short: </em>
        </strong>
        <em>
          Yes, if you are a resident of California, you are granted specific
          rights regarding access to your personal information.
        </em>
      </p>
      <p>
        California Civil Code Section 1798.83, also known as the “Shine The
        Light” law, permits our users who are California residents to request
        and obtain from us, once a year and free of charge, information about
        categories of personal information (if any) we disclosed to third
        parties for direct marketing purposes and the names and addresses of all
        third parties with which we shared personal information in the
        immediately preceding calendar year. If you are a California resident
        and would like to make such a request, please submit your request in
        writing to us using the contact information provided below.
      </p>
      <p>
        If you are under 18 years of age, reside in California, and have a
        registered account with the Services, you have the right to request
        removal of unwanted data that you publicly post on the Services. To
        request removal of such data, please{' '}
        <Link to="/project-manager-contact">Contact Us</Link> and include the
        email address associated with your account and a statement that you
        reside in California. We will make sure the data is not publicly
        displayed on the Services, but please be aware that the data may not be
        completely or comprehensively removed from our systems.
      </p>
      <p className={classes.Heading_1}>
        <span id="policyupdates" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>13. DO WE MAKE UPDATES TO THIS POLICY?</strong>
      </p>
      <p>
        <strong>
          <em>In Short: </em>
        </strong>
        <em>
          Yes, we will update this policy as necessary to stay compliant with
          relevant laws.
        </em>
      </p>
      <p>
        We may update this privacy policy from time to time. The updated version
        will be indicated by an updated “Revised” date and the updated version
        will be effective as soon as it is accessible. If we make material
        changes to this privacy policy, we may notify you either by prominently
        posting a notice of such changes or by directly sending you a
        notification. We encourage you to review this frequently to be informed
        of how we are protecting your information.
      </p>
      <p className={classes.Heading_1}>
        <span id="contact" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>14. HOW CAN YOU CONTACT US ABOUT THIS POLICY?</strong>
      </p>
      <p>
        If you have questions or comments about this policy, you may{' '}
        <Link to="/project-manager-contact">Contact Us</Link>.
      </p>
    </div>
  )
}

export default privacyPolicy
